<template>

  <main id="main">

    <!-- ======= Breadcrumbs ======= -->
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="breadcrumb-hero">
        <div class="container">
          <div class="breadcrumb-hero">
            <h2>Aggregates</h2>
          </div>
        </div>
      </div>
      <div class="container">
        <ol>
          <li><router-link :to="{ name: 'home' }">Home</router-link></li>
          <li>Products</li>
          <li>Aggregates</li>
        </ol>
      </div>
    </section><!-- End Breadcrumbs -->

    <!-- ======= Product Section ======= -->
    <section id="products" class="product">
      <div class="container">

        <div class="row">
          <product>
            <template v-slot:img>
              <a href="/img/aggregates/A_Ballast.jpg" target="_blank">
                <img src="@/assets/img/aggregates/th_A_Ballast.jpg" class="img-fluid" alt="">
              </a>
            </template>
            <template v-slot:name>
              Rail Ballast - Type A
            </template>
            <template v-slot:description>
              'A' Type Rail Ballast has a nominal size of 53mm. Typically made of the freshest blue rock available and as a result has quite a sharp angular shape.<br />
              <br />
              Please note: If you do not require high quality blue rock and the level quality assurance that comes with ballast specifically designed for use by railways,
              have a look at our 53/20 Rundown material as a more affordable alternative.<br/>
              <br/>
              <b>Standards</b><br/>
              Queensland Rail Ballast Specification CT147<br/>
              <br/>
              <b>Applications</b><br/>
              Rail ballast, Decorative.
            </template>
          </product>

          <product>
            <template v-slot:img>
              <a href="/img/aggregates/20mm_Agg.jpg" target="_blank">
                <img src="@/assets/img/aggregates/th_20mm_Agg.jpg" class="img-fluid" alt="">
              </a>
            </template>
            <template v-slot:name>
              20mm Aggregate
            </template>
            <template v-slot:description>
              20mm Aggregate is typically used as a concrete aggregate and has a nominal size of 20mm down to 14mm.
              It is typically blue in colour though can range through to a brown tint however it will always consist of hard blue rock.<br/>
              <br/>
              Also available precoated for spray sealing and other road paving applications. Please note that precoat must be pre-ordered.<br/>
              <br/>
              <b>Standards</b><br/>
              AS2758.1<br/>
              <br/>
              <b>Applications</b><br/>
              Concrete Aggregate, Drainage, Decorative, Stemming material, Retaining Wall Backfill
            </template>
          </product>

          <product>
            <template v-slot:img>
              <a href="/img/aggregates/14mm_Agg.jpg" target="_blank">
                <img src="@/assets/img/aggregates/th_14mm_Agg.jpg" class="img-fluid" alt="">
              </a>
            </template>
            <template v-slot:name>
              14mm Aggregate
            </template>
            <template v-slot:description>
              14mm Aggregate is typically used as a concrete aggregate and has a nominal size of 14mm down to  10mm.
              It is typically blue in colour though can range through to a brown tint however it will always consist of hard blue rock.<br/>
              <br/>
              Also available precoated for spray sealing and other road paving applications. Please note that precoat must be pre-ordered.<br/>
              <br/>
              <b>Standards</b><br/>
              Concrete Aggregate:<br/>
              &nbsp; AS2758.1<br/>
              Precoat Aggregate:<br/>
              &nbsp;MRTS11.22<br/>
              <br/>
              <b>Applications</b><br/>
              Concrete aggregate, Drainage, Decorative.
            </template>
          </product>

          <product>
            <template v-slot:img>
              <a href="/img/aggregates/10mm_Agg_Q.jpg" target="_blank">
                <img src="@/assets/img/aggregates/th_10mm_Agg_Q.jpg" class="img-fluid" alt="">
              </a>
            </template>
            <template v-slot:name>
              10mm Aggregate
            </template>
            <template v-slot:description>
              10mm Aggregate is typically used as a concrete aggregate and has a nominal size of 10mm down to 7mm.
              It is typically blue in colour though can range through to a brown tint however it will always consist of hard blue rock.<br/>
              <br/>
              Also available precoated for spray sealing and other road paving applications. Please note that precoat must be pre-ordered.<br/>
              <br/>
              <b>Standards</b><br/>
              Concrete Aggregate:<br/>
              &nbsp; AS2758.1<br/>
              Precoat Aggregate:<br/>
              &nbsp;MRTS11.22<br/>
              <br/>
              <b>Applications</b><br/>
              Concrete aggregate, Exposed Concrete, Pipe bedding, Drainage, Decorative.
            </template>
          </product>

          <product>
            <template v-slot:img>
              <a href="/img/aggregates/7mm_Agg_T.jpg" target="_blank">
                <img src="@/assets/img/aggregates/th_7mm_Agg_T.jpg" class="img-fluid" alt="">
              </a>
            </template>
            <template v-slot:name>
              7mm Aggregate
            </template>
            <template v-slot:description>
              7mm Aggregate is typically used as a concrete aggregate and has a nominal size of 7mm down to 5mm.
              It is typically blue in colour though can range through to a brown tint however it will always consist of hard blue rock.<br/>
              <br/>
              Also available precoated for spray sealing and other road paving applications. Please note that precoat must be pre-ordered.<br/>
              <br/>
              <b>Standards</b><br/>
              AS2758.1<br/>
              <br/>
              <b>Applications</b><br/>
              Concrete aggregate, Masonry manufacturing, Pipe bedding, Drainage, Decorative.
            </template>
          </product>

        </div>

      </div>
    </section><!-- End Product Section -->
  </main>
</template>
<script>
import Product from '@/components/Product.vue';

export default {
  components: {
    Product,
  },
};
</script>
